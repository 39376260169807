import React from "react"
import Ipad from "../components/Ipad.js"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const floading = () => {
  setTimeout(() => {
    navigate("/thanks")
  }, 4000)
  return (
    <Ipad data={{ bgColor: "var(--blu)" }}>
      <Container>
        <StaticImage
          src="../images/triangles.png"
          alt="triangle"
          className="img"
        />
        <p>IMPRIMIENDO BILLETE...</p>
      </Container>
    </Ipad>
  )
}
const Container = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  .img {
    width: 100px;
  }
  p {
    margin-top: 40px;
    width: 100%;
    font-family: GEB;
    color: white;
    font-size: 1.5rem;
  }
`

export default floading
